define('hyro/app', ['exports', 'hyro/resolver', 'ember-load-initializers', 'hyro/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    engines: {
      galleryDisplay: {
        dependencies: {
          services: ['layout', 'current-user', 'store', 'asset-map', 'socket-io', 'spinner']
        }
      }
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});