define('hyro/helpers/art-piece-check', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.artPieceCheck = artPieceCheck;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function artPieceCheck(_ref) {
    var _ref2 = _toArray(_ref),
        id1 = _ref2[0],
        id2 = _ref2[1],
        _ = _ref2.slice(2);

    return id1 === id2;
  }

  exports.default = Ember.Helper.helper(artPieceCheck);
});