define('hyro/components/marketplace-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),

    actions: {
      closeMenu: function closeMenu() {
        var menu = document.querySelector('.menu');

        menu.querySelector('.bg').style.opacity = 0;

        var menuItems = [].concat(_toConsumableArray(menu.querySelectorAll('li')));

        for (var i = 0; i < menuItems.length; i++) {
          menuItems[i].classList.remove('active');
        }

        menu.classList.remove('active');

        setTimeout(function () {
          menu.querySelector('.bg').style.display = "none";
          menu.style.display = "none";
        }, 500);
      },
      userLogout: function userLogout() {
        this.get('session').invalidate('authenticator:custom');
        this.actions.closeMenu();
        this.get('router').transitionTo('index');
      }
    }
  });
});