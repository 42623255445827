define('hyro/components/metamask-faq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    actions: {
      faqOpen: function faqOpen() {
        event.currentTarget.classList.toggle('active');
        var faq = [].concat(_toConsumableArray(document.querySelectorAll('.faq'))) || null;
        for (var i = 0; i < faq.length; i++) {
          if (event.currentTarget !== faq[i]) {
            faq[i].classList.remove('active');
          }
        }
      }
    }
  });
});