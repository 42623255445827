define('hyro/components/log-in', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    web3: Ember.inject.service('web3'),

    actions: {
      createUser: function createUser() {
        this.get('createUser')();
      }
    }
  });
});