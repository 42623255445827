define('hyro/models/transaction', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    amount: attr('number'),
    edition: attr('number'),
    tx_hash: attr('string'),
    tx_type: attr('string'),
    ethscanLink: Ember.computed('tx_hash', function () {
      return 'https://rinkeby.etherscan.io/tx/' + this.get('tx_hash');
    }),
    typeDisplay: Ember.computed('tx_type', function () {
      return this.get('tx_type') ? this.get('tx_type').charAt(0).toUpperCase() + this.get('tx_type').slice(1) : '';
    }),
    createdAt: attr('string'),
    dateDisplay: Ember.computed('createdAt', function () {
      return new Date(this.get('createdAt')).toLocaleDateString();
    }),
    artPiece: belongsTo('art-piece'),
    user: belongsTo('user')
  });
});