define('hyro/controllers/order-complete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['tx'],
    tx: null,

    ethscanLink: Ember.computed('tx', function () {
      return 'https://rinkeby.etherscan.io/tx/' + this.get('tx');
    })
  });
});