define('hyro/components/loading-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    spinner: Ember.inject.service('spinner'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('spinner').show('loading');
    }
  });
});