define('hyro/router', ['exports', 'hyro/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    previewMode: _environment.default.previewMode
  });

  Router.map(function () {
    this.route('terms');
    this.route('privacy');
    this.route('artists');
    if (!this.previewMode) {
      this.route('my-collection');
      this.route('sign-in');
      this.route('featured-piece');
      this.route('marketplace');
      this.route('order-complete');
      this.route('bids');
      this.route('offers');
      this.route('transactions');
      this.route('demo');
      this.route('art-pieces', function () {
        this.route('show', { path: '/:piece_id' });
      });
      this.route('galleries', function () {
        this.route('show', { path: '/:gallery_id' });
        this.route('new');
      });
    }
    this.mount('gallery-display');
  });

  exports.default = Router;
});