define('hyro/components/collection-item', ['exports', 'intense-images'], function (exports, _intenseImages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      openCertificateModal: function openCertificateModal(edition) {
        this.get('openCertificateModal')(edition);
      },
      openMessageModal: function openMessageModal() {
        var messageModal = document.querySelector('.artist-message-overlay') || null;
        messageModal.classList.add('active');
        messageModal.querySelector('.bg').addEventListener('click', function () {
          messageModal.classList.remove('active');
        });
        messageModal.querySelector('a.close').addEventListener('click', function () {
          messageModal.classList.remove('active');
        });
      },
      openPrintModal: function openPrintModal() {
        var printModal = document.querySelector('.print-overlay') || null;
        printModal.classList.add('active');
        printModal.querySelector('.bg').addEventListener('click', function () {
          printModal.classList.remove('active');
        });
        printModal.querySelector('a.close').addEventListener('click', function () {
          printModal.classList.remove('active');
        });
      },
      toggleOfferModal: function toggleOfferModal(artEdition) {
        this.get('toggleOfferModal')(artEdition);
      },
      enlargePiece: function enlargePiece(previewImage) {
        this.set("enlargedImage", previewImage);
        var galleryPopup = document.querySelector('.enlarge-overlay');
        galleryPopup.classList.add('active');

        // Close button and also close when you click the overlay
        galleryPopup.querySelector('.bg').addEventListener('click', function () {
          galleryPopup.classList.remove('active');
        });
        galleryPopup.querySelector('div.close').addEventListener('click', function () {
          galleryPopup.classList.remove('active');
        });
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var elements = document.querySelectorAll('.collection-img');
      (0, _intenseImages.default)(elements);
    }
  });
});