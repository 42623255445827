define('hyro/models/gallery', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    location: attr('string'),
    description: attr('string'),
    image: attr('string'),
    imageUrl: Ember.computed('image', function () {
      return 'https://ipfs.io/ipfs/' + this.get('image');
    }),
    galleryPieces: hasMany('art-piece')
  });
});