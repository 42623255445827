define('hyro/services/layout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    menuName: 'marketplace-menu',

    setMenu: function setMenu(name) {
      this.set('menuName', name);
    }
  });
});