define('hyro/components/display-collection-item', ['exports', 'gallery-display/components/display-collection-item'], function (exports, _displayCollectionItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _displayCollectionItem.default;
    }
  });
});