define('hyro/services/current-user', ['exports', 'ember-simple-auth-token/authenticators/jwt'], function (exports, _jwt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),

    load: function load() {
      var _this = this;

      var token = this.get('session.data.authenticated.accessToken');
      if (!Ember.isEmpty(token)) {
        var userId = this.getUserIdFromToken(token);
        return this.get('store').find('user', userId).then(function (user) {
          _this.set('user', user);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    getUserIdFromToken: function getUserIdFromToken(token) {
      var jwt = new _jwt.default();
      var tokenData = jwt.getTokenData(token);
      return tokenData['userId'];
    }
  });
});