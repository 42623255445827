define('hyro/components/art-piece-bids', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      toggleBidModal: function toggleBidModal(edition) {
        this.set('bidEdition', edition);
        this.toggleProperty('isShowingBidModal');
      }
    }
  });
});