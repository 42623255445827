define('hyro/adapters/application', ['exports', 'ember-data', 'hyro/config/environment', 'ember-inflector', 'ember-simple-auth-token/mixins/token-authorizer'], function (exports, _emberData, _environment, _emberInflector, _tokenAuthorizer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_tokenAuthorizer.default, {
    host: _environment.default.APP.apiHost,

    pathForType: function pathForType(type) {
      var dasherized = Ember.String.dasherize(type);
      return (0, _emberInflector.pluralize)(dasherized);
    }
  });
});