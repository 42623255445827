define('hyro/models/gallery-edition', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    edition: attr('number'),
    owner: attr('string'),
    highestBid: attr('number'),
    lowestOffer: attr('number'),
    bidAmount: attr('number'),
    offerAmount: attr('number'),
    artPiece: belongsTo('art-piece'),
    claimedAt: attr('string'),
    txHash: attr('string'),
    dateDisplay: Ember.computed('claimedAt', function () {
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(this.get('claimedAt')).toLocaleDateString("en-US", options);
    }),
    ethscanLink: Ember.computed('txHash', function () {
      return 'https://rinkeby.etherscan.io/tx/' + this.get('txHash');
    })
  });
});