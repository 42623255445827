define('hyro/controllers/galleries/new', ['exports', 'npm:buffer', 'npm:ipfs-api'], function (exports, _npmBuffer, _npmIpfsApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    fileUploading: false,

    actions: {
      save: function save() {
        var self = this;
        this.get('model').save().then(function () {
          self.transitionToRoute('galleries');
        }).catch(function (reason) {
          console.error(reason);
        });
      },
      uploadImage: function uploadImage(event) {
        this.set('fileUploading', true);
        var file = event.target.files[0];
        var reader = new window.FileReader();
        reader.readAsArrayBuffer(file);
        var self = this;
        reader.onloadend = function () {
          var ipfs = new _npmIpfsApi.default({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' });
          ipfs.files.add(new _npmBuffer.default.Buffer(reader.result), function (error, result) {
            if (error) {
              console.error(error);
              return;
            }
            self.set('model.image', result[0].hash);
            self.set('fileUploading', false);
          });
        };
      }
    }
  });
});