define('hyro/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (duration) {
    this.hasClass('gallery-display-description'), this.toValue(true), this.use('crossFade', { duration: duration }), this.reverse('toLeft', { duration: duration });
  };
});