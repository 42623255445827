define('hyro/components/app-animated', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);
      // Animate elements
      var elementsToAnimate = [].concat(_toConsumableArray(document.querySelectorAll('.animated')));

      var screen = window.scrollY + window.innerHeight / 2;

      for (var i = 0; i < elementsToAnimate.length; i++) {
        var yPos = elementsToAnimate[i].getBoundingClientRect().top + window.pageYOffset;

        if (yPos - screen < 275) {
          elementsToAnimate[i].classList.remove('animated');
          elementsToAnimate[i].style.transition = '.8s linear';
          elementsToAnimate[i].style.transform = 'translateY(0%)';
        }
      }

      document.addEventListener('scroll', function () {
        var screen = window.scrollY + window.innerHeight / 2;

        for (var _i = 0; _i < elementsToAnimate.length; _i++) {
          var _yPos = elementsToAnimate[_i].getBoundingClientRect().top + window.pageYOffset;

          if (_yPos - screen < 275) {
            elementsToAnimate[_i].classList.remove('animated');
            elementsToAnimate[_i].style.transition = '.8s linear';
            elementsToAnimate[_i].style.transform = 'translateY(0%)';
          }
        }
      });
    }
  });
});