define('hyro/components/app-header', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    layout: Ember.inject.service('layout'),
    router: Ember.inject.service(),
    demoMode: _emberGetConfig.default.demoMode,
    previewMode: _emberGetConfig.default.previewMode,

    actions: {
      closeMenu: function closeMenu() {
        var menu = document.querySelector('.menu');

        menu.querySelector('.bg').style.opacity = 0;

        var menuItems = [].concat(_toConsumableArray(menu.querySelectorAll('li')));

        for (var i = 0; i < menuItems.length; i++) {
          menuItems[i].classList.remove('active');
        }

        menu.classList.remove('active');

        setTimeout(function () {
          menu.querySelector('.bg').style.display = "none";
          menu.style.display = "none";
        }, 500);
      },
      openMenu: function openMenu() {
        var menu = document.querySelector('.menu');
        if (!menu.classList.contains('active')) {
          menu.style.display = "block";
          menu.querySelector('.bg').style.display = "block";
          setTimeout(function () {
            menu.querySelector('.bg').style.opacity = 1;
            menu.classList.add('active');

            var menuItems = [].concat(_toConsumableArray(menu.querySelectorAll('li')));

            var _loop = function _loop(i) {
              setTimeout(function () {
                menuItems[i].classList.add('active');
              }, 100 * i);
            };

            for (var i = 0; i < menuItems.length; i++) {
              _loop(i);
            }
          }, 200);
        }

        document.querySelector('.menu .bg').addEventListener('click', function () {
          // Close button and also close when you click the overlay
          var menu = document.querySelector('.menu');

          menu.querySelector('.bg').style.opacity = 0;

          var menuItems = [].concat(_toConsumableArray(menu.querySelectorAll('li')));

          for (var i = 0; i < menuItems.length; i++) {
            menuItems[i].classList.remove('active');
          }

          menu.classList.remove('active');

          menu.querySelector('.bg').style.display = "none";
          menu.style.display = "none";
        });
        document.querySelector('.menu a.close').addEventListener('click', function () {
          var menu = document.querySelector('.menu');

          menu.querySelector('.bg').style.opacity = 0;

          var menuItems = [].concat(_toConsumableArray(menu.querySelectorAll('li')));

          for (var i = 0; i < menuItems.length; i++) {
            menuItems[i].classList.remove('active');
          }

          menu.classList.remove('active');

          menu.querySelector('.bg').style.display = "none";
          menu.style.display = "none";
        });
      }
    }
  });
});