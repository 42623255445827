define('hyro/components/blurred-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mouseMove: function mouseMove(event) {
      event.preventDefault();
      var container = document.querySelector(".blur").getBoundingClientRect();
      var upX = event.clientX;
      var upY = event.clientY;
      var mask = document.querySelector('#mask1 circle');
      mask.setAttribute("cy", upY - container.top + 'px');
      mask.setAttribute("cx", upX - container.left + 'px');
    }
  });
});