define('hyro/components/rareness-overlay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      openRarenessModal: function openRarenessModal() {
        var rarenessModal = document.querySelector('.rareness-overlay') || null;
        rarenessModal.classList.add('active');
        rarenessModal.querySelector('.bg').addEventListener('click', function () {
          rarenessModal.classList.remove('active');
        });
        rarenessModal.querySelector('a.close').addEventListener('click', function () {
          rarenessModal.classList.remove('active');
        });
      }
    }
  });
});