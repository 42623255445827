define('hyro/gallery-display/tests/addon.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | addon');

  QUnit.test('addon/components/display-collection-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/display-collection-item.js should pass ESLint\n\n');
  });

  QUnit.test('addon/controllers/collection.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/collection.js should pass ESLint\n\n');
  });

  QUnit.test('addon/controllers/display.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/display.js should pass ESLint\n\n');
  });

  QUnit.test('addon/engine.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/engine.js should pass ESLint\n\n');
  });

  QUnit.test('addon/resolver.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/resolver.js should pass ESLint\n\n');
  });

  QUnit.test('addon/routes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes.js should pass ESLint\n\n');
  });

  QUnit.test('addon/routes/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/application.js should pass ESLint\n\n');
  });

  QUnit.test('addon/routes/collection.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/collection.js should pass ESLint\n\n');
  });

  QUnit.test('addon/routes/display.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/display.js should pass ESLint\n\n');
  });
});