define('hyro/controllers/my-collection', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    web3: Ember.inject.service('web3'),
    demoMode: _emberGetConfig.default.demoMode,
    certificateEdition: null,
    isShowingOfferModal: false,
    pieceOffered: null,

    actions: {
      openCertificateModal: function openCertificateModal(edition) {
        this.set('certificateEdition', edition);
        var certificateModal = document.querySelector('.certificate-overlay') || null;
        certificateModal.classList.add('active');
        certificateModal.querySelector('.bg').addEventListener('click', function () {
          certificateModal.classList.remove('active');
        });
        certificateModal.querySelector('a.close').addEventListener('click', function () {
          certificateModal.classList.remove('active');
        });
      },
      toggleOfferModal: function toggleOfferModal(artEdition) {
        this.set('editionOffered', artEdition);
        // Close the art piece data in case the edition gets sold and removed
        this.set('pieceOffered', artEdition.get('artPiece'));
        this.toggleProperty('isShowingOfferModal');
      }
    }
  });
});