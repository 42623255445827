define('hyro/initializers/web3', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'web3', 'service:web3');
  }

  exports.default = {
    initialize: initialize
  };
});