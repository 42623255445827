define('hyro/helpers/format-amount', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatAmount = formatAmount;
  function formatAmount(amount /*, hash*/) {
    if (amount[0] && parseFloat(amount[0]) !== 0) {
      return Ember.String.htmlSafe(amount + "<img style=\"height: 26px\" src=\"/assets/images/eth.svg\" alt=\"\"/>");
    } else {
      return 'N/A';
    }
  }

  exports.default = Ember.Helper.helper(formatAmount);
});