define('hyro/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    disableHeader: Ember.computed('currentPath', function () {
      return this.get('currentPath') == 'demo' || this.get('currentPath') == 'gallery-display.display';
    }),

    disableFooter: Ember.computed('currentPath', function () {
      return this.get('currentPath') == 'demo' || this.get('currentPath') == 'gallery-display.display';
    })
  });
});