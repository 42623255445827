define('hyro/components/art-piece-carousel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    carousel: null,
    leftScroll: null,
    rightScroll: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('carousel', {});
      this.set('carousel.e', document.getElementById('gallery-carousel'));
      this.set('carousel.items', document.getElementById('carousel-items'));
      this.set('leftScroll', document.getElementById('left-scroll-carousel-button'));
      this.set('rightScroll', document.getElementById('right-scroll-carousel-button'));

      this.setScrollOpacity();
    },
    MouseWheelHandler: function MouseWheelHandler(e, element) {
      var delta = 0;
      if (typeof e === 'number') {
        delta = e;
      } else {
        if (e.deltaX !== 0) {
          delta = e.deltaX;
        } else {
          delta = e.deltaY;
        }
        e.preventDefault();
      }

      element.scrollLeft -= delta;
      this.setScrollOpacity();
    },
    setLeftScrollOpacity: function setLeftScrollOpacity() {
      if (this.isScrolledAllLeft()) {
        this.set('leftScroll.style.opacity', 0);
      } else {
        this.set('leftScroll.style.opacity', 1);
      }
    },
    isScrolledAllLeft: function isScrolledAllLeft() {
      return this.get('carousel.items.scrollLeft') === 0;
    },
    isScrolledAllRight: function isScrolledAllRight() {
      var items = this.get('carousel.items');
      if (items.scrollWidth > items.offsetWidth) {
        if (items.scrollLeft + items.offsetWidth === items.scrollWidth) {
          return true;
        }
      } else {
        return true;
      }

      return false;
    },
    setRightScrollOpacity: function setRightScrollOpacity() {
      if (this.isScrolledAllRight()) {
        this.set('rightScroll.style.opacity', 0);
      } else {
        this.set('rightScroll.style.opacity', 1);
      }
    },
    setScrollOpacity: function setScrollOpacity() {
      this.setLeftScrollOpacity();
      this.setRightScrollOpacity();
    },


    actions: {
      leftScrollClick: function leftScrollClick() {
        this.MouseWheelHandler(100, this.get('carousel.items'));
      },
      rightScrollClick: function rightScrollClick() {
        this.MouseWheelHandler(-100, this.get('carousel.items'));
      },
      updateSelectedPiece: function updateSelectedPiece(pieceId) {
        this.get('updateSelectedPiece')(pieceId);
      }
    }
  });
});