define('hyro/controllers/art-pieces/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    web3: Ember.inject.service('web3'),
    spinner: Ember.inject.service('spinner'),
    isShowingModal: false,
    bidPlaced: false,
    bidProcessing: false,
    bidAmount: null,
    bidPiece: null,
    highestBid: 0.0,
    lowestOffer: 0.0,

    actions: {
      toggleBidModal: function toggleBidModal(artPiece) {
        //For right now we should create an art edition wrapper to keep this consistent with offers
        var artEdition = {
          artPiece: artPiece
        };
        this.set('bidPiece', artEdition);
        this.toggleProperty('isShowingBidModal');
      },
      bidAgain: function bidAgain() {
        this.set('bidPlaced', false);
      }
    }
  });
});