define('hyro/models/art-piece', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    assetMap: Ember.inject.service('asset-map'),

    artist: attr('string'),
    name: attr('string'),
    description: attr('string'),
    image: attr('string'),
    ipfsHash: attr('string'),
    editions: attr('number'),
    claimed: attr('number'),
    nextEdition: Ember.computed('claimed', function () {
      if (this.get('claimed') === this.get('editions')) {
        return this.get('claimed');
      } else {
        return this.get('claimed') + 1;
      }
    }),
    status: Ember.computed('claimed', function () {
      if (this.get('soldOut')) {
        return 'SOLD OUT';
      } else {
        return 'Available';
      }
    }),
    soldOut: Ember.computed('claimed', function () {
      return this.get('claimed') === this.get('editions');
    }),
    highestBid: attr('number'),
    lowestOffer: attr('number'),
    artEditions: hasMany('art-edition'),
    gallery: belongsTo('gallery'),
    fullImage: Ember.computed('ipfsHash', function () {
      return 'https://ipfs.io/ipfs/' + this.get('ipfsHash');
    }),
    previewImage: Ember.computed('image', function () {
      return this.get('image') + '_preview';
    }),
    previewImagePath: Ember.computed('image', function () {
      return this.get('image') ? this.get('assetMap').resolve('assets/images/' + this.get('image') + '_preview.png') : '';
    }),
    blurredImage: Ember.computed('image', function () {
      return this.get('image') + '_blurred';
    })
  });
});