define('hyro/routes/galleries/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.store.createRecord('gallery');
        },


        actions: {
            willTransition: function willTransition() {
                this._super.apply(this, arguments);
                var gallery = this.controller.get('model');
                gallery.rollbackAttributes();
            }
        }
    });
});